<template>
  <div class="user-management-page">
    <navigation></navigation>

    <div class="dashboard-container-right">
      <div v-if="!user" class="loader-dashboard-page mt-4"><div></div></div>
      <div class="user-main-wrapper" v-else>
        <div style="display: flex">
          <button
            type="button"
            style="
              background: rgba(255, 255, 255, 0.5);
              box-shadow: none;
              font-size: 24px;
              padding: 0px 7px;
              margin-top: 17px;
              margin-right: 7px;
              color: #0084b6;
              outline: none !important;
            "
            class="btn btn-default pull-left back-button-title mr-2"
            @click="$router.push({ name: 'UserManagementPage' })"
          >
            <strong><i class="glyphicon glyphicon-chevron-left"></i></strong>
          </button>
          <h2 class="title mt-4">User: {{ user.first_name }} {{ user.last_name }}</h2>
        </div>
        <hr />

        <div class="user-wrapper" key="user_info">
          <div class="group">
            <label>Username / Email:</label>
            <div class="data-display">{{ user.email }}</div>
          </div>
          <div class="group">
            <label>Contact Person:</label>
            <div class="data-display">{{ user.first_name }} {{ user.last_name }}</div>
          </div>
          <div class="group">
            <label>Company:</label>
            <div class="data-display">{{ user.company_name }}</div>
          </div>
          <div class="group">
            <label>Address:</label>
            <div class="data-display">{{ user.company_address }}, Aruba</div>
          </div>
          <div class="group">
            <label>Phone Number:</label>
            <div class="data-display">{{ user.phone_number }}</div>
          </div>
          <div class="group" style="display: flex; justify-content: space-between; align-items: center">
            <div>
              <label>Verified Email:</label>
              <div class="data-display text-success" v-if="user.email_verified_at">Verified</div>
              <div class="data-display text-danger" v-else>Not Verified</div>
            </div>
            <div style="display: flex">
              <div v-if="!user.email_verified_at">
                <button class="btn btn-info" @click="resendVerificationMail">Re-send verification email</button>
              </div>
            </div>
          </div>
          <div class="group no-border" style="display: flex; justify-content: space-between; align-items: center">
            <div>
              <label>Roles:</label>
              <div class="data-display" v-for="role in user.user_roles" :key="role.id" style="text-transform: capitalize">
                {{ helpers.slugToText(role.name) }}
              </div>
            </div>
            <div v-if="user.email_verified_at" class="mt-2">
              <select
                v-if="!isRole('admin')"
                :value="selectedRole"
                class="form-control"
                style="width: 200px"
                @change="$event => convertToRole($event.target.value)"
              >
                <option value="client">Client</option>
                <option value="lab_admin">Lab Admin</option>
                <option value="accounting">Accounting</option>
              </select>
              <div style="font-size: 12px" class="text-danger" v-if="isRole('lab_admin') || isRole('accounting')">
                Be carefull who you give this role
              </div>
              <!-- <div v-if="isRole('client')" class="mt-2">
                <button class="btn btn-info" @click="convertToRole('lab_admin')">Convert to "Lab Admin" role</button>
                <div style="font-size: 12px" class="text-danger">Be carefull who you give this role</div>
              </div>
              <button class="btn btn-info" v-if="isRole('lab_admin')" @click="convertToRole('client')">Convert to "Client" role</button>
              <button class="btn btn-info" v-if="isRole('client')" @click="convertToRole('accounting')">Convert to "Accounting" role</button> -->
            </div>
            <div v-else style="font-size: 12px">User must be verified to assign other role</div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/generic/Navigation.vue';
import * as helpers from '@/helpers/functions.js';
import { mapGetters } from 'vuex';
export default {
  components: { Navigation },
  computed: {
    ...mapGetters({
      isDashboardAdmin: 'isDashboardAdmin'
    })
  },
  data() {
    return {
      user: null,
      userId: this.$route.params.userId,
      helpers
    };
  },
  computed: {
    selectedRole() {
      const roles = ['client', 'lab_admin', 'accounting'];
      for (let role of roles) {
        if (this.user.user_roles.some(userRole => userRole.name === role)) {
          return role;
        }
      }
      return null; // or a default value
    }
  },
  async created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      this.$store.dispatch('getSingleUser', this.userId).then(data => {
        this.user = data;
      });
    },
    resendVerificationMail() {
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('sentVerificationMailAdmin', this.user.id)
        .then(data => {
          if (data.status == 'verification-link-sent') {
            this.$snotify.success(`Email sent to ${this.user.email}`, '', {
              timeout: 4000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              position: 'rightTop'
            });
          }
        })
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    isRole(roleName) {
      let returnValue = false;
      for (let index = 0; index < this.user.user_roles.length; index++) {
        let role = this.user.user_roles[index];
        if (role.name === roleName) {
          returnValue = true;
          break;
        }
      }
      return returnValue;
    },
    convertToRole(role) {
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('assignRole', { userId: this.user.id, role: role })
        .then(data => {
          this.user = data;
          this.$snotify.success(`User role changed to ${role}`, '', {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            position: 'rightTop'
          });
        })
        .finally(_ => this.$store.commit('setTransLoader', false));
    }
  }
};
</script>

<style></style>
